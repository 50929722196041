import React, { Component } from 'react'
import Layout from '../../components/Layout'
import DiversityBadge from '../../components/DiversityBadge'

import 'isomorphic-fetch'

export default class Job extends Component {
    state = { displayJob: {}, boardCode: null, jobLoaded: false }

    componentDidMount = () => {
        let host = window.location.hostname
        let boardCode = new URLSearchParams(window.location.search).get('pos')

        fetch(`//${host}/marketing/getJobPost/${boardCode}`, {
            credentials: 'include',
            mode: 'cors',
        })
            .then(res => res.json())
            .then(data =>
                this.setState({
                    displayJob: data,
                    boardCode: boardCode,
                    jobLoaded: true,
                })
            )
    }

    applyToggle = () => {
        var resumatorApplyForm = document.getElementById('resumator-applyform')
        var resumatorApplyFrame = document.getElementById(
            'resumator-applyframe'
        )
        var resumatorApplyButton = document.getElementById(
            'resumator-applybutton'
        )

        if (resumatorApplyForm.style.display !== 'block') {
            resumatorApplyButton.value = 'Cancel'
            resumatorApplyForm.style.display = 'block'
            const resumatorSource = 'Our Career Page Widget'
            resumatorApplyFrame.src =
                'https://HomeSpotter.applytojob.com/apply/embed/form/' +
                this.state.boardCode +
                '/' +
                resumatorSource
        } else {
            resumatorApplyButton.value = 'Apply Now'
            resumatorApplyForm.style.display = 'none'
        }
        // window.jQuery(resumatorApplyButton).trigger("change");
    }

    render() {
        return (
            <Layout bodyClass="careers listing">
                <DiversityBadge />
                {this.state.jobLoaded ? (
                    <div className="job-listing">
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: this.state.displayJob.title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.displayJob.description,
                            }}
                        />
                        <div className="buttons center">
                            <div
                                className="resumator-buttons"
                                style={{ margin: '25px 0', padding: 0 }}
                            >
                                <ul
                                    style={{
                                        listStyleType: 'none',
                                        margin: 0,
                                        padding: 0,
                                        height: '20px',
                                    }}
                                >
                                    <li
                                        style={{
                                            float: 'left',
                                            margin: '0 10px 0 0',
                                            padding: 0,
                                        }}
                                    >
                                        <input
                                            type="button"
                                            onClick={this.applyToggle}
                                            name="resumator-applybutto"
                                            id="resumator-applybutton"
                                            className="resumator-button resumator-apply-button button button-primary"
                                            value="Apply Now"
                                        />
                                    </li>
                                    <li
                                        style={{
                                            float: 'left',
                                            margin: '0 10px 0 0',
                                            padding: 0,
                                        }}
                                    >
                                        <a
                                            className="button button-plain"
                                            href={
                                                'http://app.jazz.co/app/share/' +
                                                this.state.boardCode
                                            }
                                            rel="noopener noreferrer"
                                            style={{ display: 'block' }}
                                            target="_blank"
                                            type="button"
                                        >
                                            Share Job
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="resumator-form-iframe"
                            id="resumator-applyform"
                            style={{ display: 'none', width: '100%' }}
                        >
                            <iframe
                                id="resumator-applyframe"
                                frameBorder="0"
                                style={{
                                    backgroundColor: 'transparent',
                                    marginTop: '15px',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    height: '1140px',
                                    width: '100%',
                                }}
                                src=""
                                title="resumator-applyframe"
                            />
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </Layout>
        )
    }
}
